import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import './breadcrumbnav.css';
import { BreadcrumbNavProps } from './types';

// Example of navigation array
// const navigation = [
//     {
//         key: 0, // optional
//         label: 'Label',
//         link: '', // optional
//         className: '', // optional
//         overlay: null, // optional
//         icon: '', // optional
//     }
// ];

const BreadcrumbNav:FC<BreadcrumbNavProps> = ({ navigation, separator }) => (
    <Breadcrumb
        separator={separator}
        className="breadcrumb"
        items={
            navigation.map((nav, navIndex) => ({
                title: nav.link
                    ? <Link className={nav.className} to={nav.link}>{nav.label}</Link>
                    : <span className={nav.className}>{nav.label}</span>,
                key: nav.key || navIndex,
            }))
        } />
);

BreadcrumbNav.defaultProps = {
    // separator: '/',
};

export default BreadcrumbNav;
