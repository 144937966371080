import React from 'react';
import { Layout } from 'antd';
import './footer.css';
import { FooterProps } from './types';

const Footer = ({
    children,
    floating,
    beforeFooter,
    afterFooter,
}:FooterProps) => (
    <>
        {beforeFooter}
        <Layout.Footer className={`footer ${floating ? 'floating-footer' : ''}`}>
            {children}
        </Layout.Footer>
        {afterFooter}
    </>
);

Footer.defaultProps = {
    children: null,
    floating: false,
    beforeFooter: '',
    afterFooter: '',
};

export default Footer;
