import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import logoSrc from '../../../assets/images/logo.png';
import bgSrcMob from '../../../assets/images/bg-mobile@2x.png';
import bgSrcDeskt from '../../../assets/images/bg@2x.png';
import icon from '../../../assets/favicon.ico';
import './authLayout.scss';
import constants from '../../../core/constants';
import { LayoutProps } from './types';

const { APP_NAME } = constants;

const AuthLayout:FC<LayoutProps> = ({ children }) => (
    <div className="login-wrap">
        <Helmet
            title={`${APP_NAME}`}
            titleTemplate={`${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' },
            ]}>
            <link rel="shortcut icon" href={icon} />
        </Helmet>
        <div className="login-info">
            <div className="bg-absolute ">
                <picture>
                    <source srcSet={bgSrcDeskt} media="(min-width: 768px)" />
                    <img src={bgSrcMob} alt={`${APP_NAME}`} />
                </picture>
            </div>
            <div className="login-info__text">
                <h1 className="visibility">Portal booster</h1>
                <img src={logoSrc} alt="logo" className="main-logo" />
                <h2 className="title">Client Portal</h2>
                <p className="description">
                    Welcome to the client Internal Compliance Plan (ICP) Review Portal.
                    The purpose of this tool is to provide a secure platform where your Internal Compliance
                    Programme (ICP) can be used to assess the export control risk exposure of the business.
                    Following submission it will be compared with internationally recommended best practice and a bespoke gap analysis performed.
                </p>
            </div>
        </div>
        {children}
    </div>
);

export default AuthLayout;
