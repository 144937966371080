import React from 'react';
import LoginForm from '../../components/Form/LoginForm';
import AuthLayout from '../../components/layout/AuthLayout';

function LoginPage() {

    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    );
}
export default LoginPage;
