import React from 'react';

import TwoFactorAuthForm from '../../components/Form/TwoFactorAuthForm';
import AuthLayout from '../../components/layout/AuthLayout';

function TwoFactorAuth() {

    return (
        <AuthLayout>
            <TwoFactorAuthForm />
        </AuthLayout>
    );
}
export default TwoFactorAuth;
