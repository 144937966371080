import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import ResetPasswordForm from '../../components/Form/ResetPasswordForm';
import bgSrcMob from '../../assets/images/bg-mobile@2x.png';
import bgSrcDeskt from '../../assets/images/bg@2x.png';
import icon from '../../assets/favicon.ico';
import '../Login/login.scss';
import constants from '../../core/constants';
import { ResetPasswordProps } from './types';

const { APP_NAME } = constants;

const ResetPassword:FC<ResetPasswordProps> = ({ match }) => {

    const backLink = {
        link: '/',
        title: '',
    };

    // Probably need a function to check the reset code first before showing the form
    console.log(match);

    return (
        <div className="login-wrap">
            <Helmet
                title={`${APP_NAME}`}
                titleTemplate={`${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <div className="login-info">
                <div className="bg-absolute ">
                    <picture>
                        <source srcSet={bgSrcDeskt} media="(min-width: 768px)" />
                        <img src={bgSrcMob} alt={`${APP_NAME}`} />
                    </picture>
                </div>
            </div>
            <ResetPasswordForm backLink={backLink} />
        </div>
    );
};

export default ResetPassword;
