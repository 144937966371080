import React from 'react';
import { Row, Col, Layout, Dropdown, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { UserIcon } from '../../Elements/CustomSVGIcon';
import Menu from '../Menu';
import type { HeaderProps } from './types';

import './header.css';

const Header = (props:HeaderProps) => {
    const {
        // uncomment this if you want to use toolbar
        // toolbar,
        menu,
        backLink,
        title,
        menuPlacement,
        menuWidth,
        floating,
        beforeHeader,
        afterHeader,
    } = props;

    const items:MenuProps['items'] = menu ? [...menu] : [];

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row justify="space-between" align="middle" style={{ height: 'inherit' }}>
                    <Col>
                        <Row align="middle">
                            <Col>
                                {menu ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : null}
                            </Col>
                            <Col>
                                {backLink}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>
                    {/*
                    // uncomment this if you want to use toolbar
                    <Col>
                        <div className="d-flex justify-content-end">
                            {toolbar.map((button, buttonIndex) => (
                                <Button
                                    key={button.id || buttonIndex}
                                    size={button.size || 'default'}
                                    className="toolbar-button"
                                    href={button.link || '#'}
                                    onClick={button.onClick || null}>

                                    <span>{button.label}</span>
                                </Button>
                            ))}
                        </div>
                    </Col> */
                    }
                    <Col>
                        <Dropdown arrow menu={{ items }} trigger={['click']} placement="bottomRight">
                            <div>
                                <Avatar className="header__avatar" icon={<UserIcon />} />
                                <DownOutlined />
                            </div>
                        </Dropdown>

                    </Col>
                </Row>
            </Layout.Header>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    logo: '',
    beforeHeader: null,
    afterHeader: null,
};

export default Header;
