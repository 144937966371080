import React, { FC } from 'react';
import { CoreRouter, LogoutRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ResetPassword from '../pages/ResetPassword';
import TwoFactorAuth from '../pages/TwoFactorAuth';

const Routes: FC = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/dashboard', component: Dashboard, type: 'default' },
        { path: '/reset-password/:token/:email', component: ResetPassword, type: 'default' },
        { path: '/two-factor', component: TwoFactorAuth, type: 'default' },
        { path: '/logout', component: LogoutRoute, type: 'default' },
        { path: '/auth/dashboard', component: Dashboard, type: 'auth' },
    ]} />
);

export default Routes;
