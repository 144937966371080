import React from 'react';
import { Button } from 'antd';
// import { useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import BreadcrumbNav from '../../components/layout/BreadcrumbNav';
import Logout from '../../components/Logout';

import './dashboard.scss';

const Dashboard = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', icon: 'home', style: { fontSize: 24, color: 'red' } }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];

    const backLink = (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    return (
        <Main
            className="login"
            title={<div>Welcome</div>}
            headerTitle={<div>Dashboard</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2020</div>}
            floatingFooter
            backLink={backLink}
            breadcrumb={navigation}>
            <BreadcrumbNav separator=">" navigation={navigation} />
            <div style={{ marginTop: '20px' }}>
                <h1>Dashboard</h1>
            </div>
            <Logout />
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
