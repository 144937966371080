import React from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { twoFactorConfirmation, twoFactorResend } from '../../../core/user/userApi';
import { FinishedFailedProps, valuesProps } from './types';
import { AppDispatch, CombinedStates } from '../../../core/types';

const TwoFactorAuthForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        isFetching,
    } = useSelector((state:CombinedStates) => state.user);
    const [form] = Form.useForm();
    const onFinish = (values:valuesProps) => {
        dispatch(twoFactorConfirmation({
            code: values.code,
        }));
    };

    const onFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    return (
        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title h-1">Two Factor Authentication</h3>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical">
                    <Form.Item
                        label="Two factor code"
                        name="code"
                        rules={[{ required: true, message: 'Please input code!' }]}>
                        <Input placeholder="012345" />
                    </Form.Item>

                    <Form.Item>

                        {isFetching ? (
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3" loading>
                                Sign In
                            </Button>
                        ) : (
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style">
                                Sign In
                            </Button>
                        )}
                    </Form.Item>
                </Form>
                <div className="text-center">
                    <Button onClick={() => twoFactorResend()} type="link" className="button-link mlp-25" href="#">
                        Resend code
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TwoFactorAuthForm;
