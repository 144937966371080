import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, CombinedStates } from '../../../core/types';
import { loginUser, forgotPassword } from '../../../core/user/userApi';
import './loginForm.scss';
import { FinishedFailedProps, valuesProps } from './types';

const LoginForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const {
        isFetching,
        isLoggedIn,
    } = useSelector((state:CombinedStates) => state.user);

    const [
        forgetPasswordModalVisible,
        setForgetPasswordModalVisible,
    ] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values: valuesProps) => {
        dispatch(loginUser({
            email: values.email,
            password: values.password,
        }));
    };

    // const onFinishFailed = (errorInfo: FinishedFailedProps) => {
    //     const { errorFields } = errorInfo;
    //     form.scrollToField(errorFields[0]?.name);
    // };

    const onFinishFailed:FC<FinishedFailedProps> = ({ errorFields }) => {
        form.scrollToField(errorFields[0]?.name);
        return null;
    };

    const handleForgotFinish = (values: valuesProps) => {
        dispatch(forgotPassword({
            email: values.email,
        }));

        // setTimeout(() => { setForgetPasswordModalVisible(false); }, 1500);

    };

    const forgotFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigation('/dashboard');
        }
    }, [isLoggedIn]);

    return (
        <>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="form-title h-1">Sign In</h3>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                            <Input placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password.' }]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>

                        <Form.Item>

                            {isFetching ? (
                                <Button type="primary" size="large" htmlType="submit" block className="mt-3" loading>
                                    Sign In
                                </Button>
                            ) : (
                                <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style">
                                    Sign In
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button onClick={() => setForgetPasswordModalVisible(true)} type="link" className="button-link mlp-25" href="#">
                            Forgotten password
                        </Button>
                    </div>
                </div>
            </div>

            <Modal centered width={540} footer={null} open={forgetPasswordModalVisible} onOk={() => { setForgetPasswordModalVisible(false); }} onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 title-text-md  text-center">Forgot password</h4>
                <div className="form-wrap">
                    <Form onFinish={handleForgotFinish} onFinishFailed={forgotFinishFailed} layout="vertical">
                        <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide" loading={isFetching}>
                                Reset my password
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                cancel
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>

        </>
    );
};

export default LoginForm;
