import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../core/user/userApi';
import './logout.scss';
import { AppDispatch, CombinedStates } from '../../core/types';

const Logout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        isFetching,
    } = useSelector((state:CombinedStates) => state.user);

    const handleLogout = (e:React.MouseEvent) => {
        e.preventDefault();
        dispatch(logoutUser());
    };

    return (
        <Button
            onClick={handleLogout}
            type="primary"
            htmlType="submit"
            className="wide"
            loading={isFetching}>
            Logout
        </Button>
    );
};

export default Logout;
